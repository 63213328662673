import { baseLog } from '@actr/logger';

export enum SingleOrderLogs {
    NOT_FOUND_ORDER,
    LOGIN,
}

const singleOrderLogs = baseLog.extend('account', 'singleOrder').bindEnum(SingleOrderLogs);

export default singleOrderLogs;
