import { useInject } from '@actr/di';
import { NavigationType, useNavigation } from '@actr/hooks';
import { AuthService, AuthStore } from '@actr/stores';
import { SiteLoader } from '@actr/widgets';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import SingleOrderRoutes from 'Router/single-order/routes';
import SingleOrderLoginStore from 'Store/single-order/login';

const singleAuthHoc = (Page: FC) => observer(() => {
  const auth = useInject(AuthStore);

  const singleOrderLoginStore = useInject(SingleOrderLoginStore);
  const authService = useInject(AuthService);

  const { navigate, searchParams, routeParams } = useNavigation(SingleOrderRoutes.LOGIN);

  const [isReady, setIsReady] = useState(false);

  const orderSig = routeParams.sig || searchParams.sig;

  // проверка автаризации
  useEffect(() => {
    if (singleOrderLoginStore.isHydrated && orderSig) {
      const orderFromLS = singleOrderLoginStore.ordersAuthInfo.find(({ sig: signature }) => (orderSig === signature));

      const toLogin = () => {
        navigate(SingleOrderRoutes.LOGIN, {
          routeParams: {
            sig: orderSig,
          },
          navigationType: NavigationType.REPLACE
        });
      };

      if (!auth.token || !orderFromLS) {
        toLogin();
        setIsReady(true);
      } else {
        authService.getMe()
          .catch(() => {
            toLogin();
          })
          .finally(() => {
            setIsReady(true);
          });
      }
    }
  }, [singleOrderLoginStore.isHydrated]);

  if (!isReady) {
    return (
      <SiteLoader />
    );
  }

  return (
    <Page />
  );
});

export default singleAuthHoc;
